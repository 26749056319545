import { SECOND_MS } from "@utils/dateTime";
import { getAccountRoleFallbackRoute } from "@utils/routes";
import { LoginPage } from "components/auth";
import { useAuth } from "contexts/AuthProvider";
import type { NextPage } from "next";
import { useEffect } from "react";

const REROUTE_TIMEOUT = SECOND_MS * 2;

const Login: NextPage = () => {
  const { accountRole, isAuthenticated } = useAuth();
  const { setActiveRoute, isLoadingRoute } = useAuth();

  useEffect(() => {
    const rerouteOnAuth = () => {
      if (isAuthenticated && !isLoadingRoute) {
        setActiveRoute(getAccountRoleFallbackRoute(accountRole));
      }
    };
    const timeoutId = setTimeout(rerouteOnAuth, REROUTE_TIMEOUT);
    return () => clearTimeout(timeoutId);
  }, [isAuthenticated, isLoadingRoute, accountRole, setActiveRoute]);

  return <LoginPage />;
};

export default Login;
